<template>
  <div>
    <AdminPromotionVideo v-if="action === 'outstanding'" />
    <AdminRewardsCompleted v-else/>
  </div>
</template>

<script>
import AdminPromotionVideo from '../../../components/Admin/Rewards/AdminRewardsVideo'
import AdminRewardsCompleted from '../../../components/Admin/Rewards/AdminRewardsCompleted'
export default {
  name: 'AdminPromotion',
  components: {
    AdminPromotionVideo,
    AdminRewardsCompleted
  },
  data () {
    return {
      action: this.$route.params.action
    }
  }
}
</script>

<style scoped>

</style>
