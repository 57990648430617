<template>
  <b-modal @shown="getData" :id="id" ref="modal" :title="title " size="lg" header-class="mb-3" footer-class="border-0" ok-only :ok-title-html="$t('close_button')">
    <div class="m-4">
      <Carousel :images="getVideosFromAssets(assets)" :indicators="true"/>
    </div>
  </b-modal>
</template>

<script>
import Carousel from '../../Global/CardCarousel'
import helper from '../../../helper'

export default {
  name: 'AdminRewardsCompletedModal',
  components: {
    Carousel
  },
  data () {
    return {
      title: '',
      assets: []
    }
  },
  props: {
    id: {
      type: String,
      required:
      false,
      default:
      'rewards-complete-modal'
    },
    data: Object
  },
  methods: {
    getData () {
      if (Object.keys(this.data).length > 0) {
        this.title = this.data.gig.title
        this.assets = this.data.gig.asset
      }
    },
    getVideosFromAssets (media) {
      let videos = []
      media.forEach(function (asset) {
        let video = {
          'src': helper.formatCloudinaryUrl(asset, 'movie_large'),
          'type': 2,
          'mp4': helper.formatCloudinaryUrl(asset, 'movie_mp4_large')
        }
        videos.push(video)
      })

      return videos
    }
  }
}
</script>
