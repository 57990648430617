<template>
  <div>
    <div class="container">
      <div class="breadcrumbs bg-transparent position-absolute mb-4 mt-1">
        <div class="text-left small px-3 mt-1 mb-sm-4 mb-3">
          <a class="clickable" @click="$router.push('/admin')">{{ $t('admin_dashboard').toUpperCase() }}</a>
          <i class="mr-3 ml-2 fa-solid fa-caret-right"></i>
          <a class="clickable" @click="$router.push('/admin/rewards')">{{ $t('rewards').toUpperCase() }}</a>
          <i class="mr-3 ml-2 fa-solid fa-caret-right"></i>
          {{ $t('rewards_' + this.action).toUpperCase() }} / #{{ promotionHash }}
        </div>
      </div>
      <div class="main-container">
        <h1><strong>{{ $t('rewards_' + this.action) }}</strong></h1>
        <div class="container" v-if="showNoResults">
          <b-alert v-model="showNoResults" variant="light">
            {{ $t('no_rewards_found_' + action) }}
          </b-alert>
        </div>
        <div class="container" v-if="dataLength > 0">
          <div class="col-xl-12 content-box">
            <div class="row row-featured row-featured-category">
              <div class="col-xl-12  box-title border-bottom ">
                <div class="inner"><h2>{{ paginationTitle() }}</h2>
                </div>
              </div>
              <div class="container mb-3 mt-3 table-responsive">
                <table class="table table-striped table-bordered add-manage-table table-hover"
                       data-filter="#filter" data-filter-text-only="true">
                  <thead>
                  <tr>
                    <th>{{ $t('username') }}</th>
                    <th>{{ $t('etn_price') }}</th>
                    <th>{{ $t('view') }}</th>
                  </tr>
                  </thead>
                  <tbody class="mb-md-0 mb-3 text-left">
                  <tr v-for="(reward, index) in rewardsData" :key="index" class="cursor">
                    <td data-title="name">{{ reward.username }}</td>
                    <td data-title="active">{{ reward.etn_price }} ETN</td>
                    <td data-title="view" class="text-center">
                      <b-button variant="primary" v-on:click="showModal(reward)">{{ $t('view') }}</b-button>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <Pagination :perPage="rewardsPerPage" :currentPage="currentPage" :total="rewardsPerPage" v-on:paginationUpdate="updatePagination" v-if="totalRewards > rewardsPerPage"
                      class="mt-3"/>
        </div>
        <div v-if="loadingRewards">
          <b-spinner class="mt-5 mb-2 mb-md-4" large label="Large Spinner"/>
          <div class="mb-5">{{ $t('loading') }}</div>
        </div>
      </div>
    </div>
    <AdminRewardsCompletedModal :data="completedModalData"/>
  </div>
</template>

<script>
import APIService from '../../../apiService'
import Pagination from '../../Global/Pagination'
import AdminRewardsCompletedModal from './AdminRewardsCompletedModal'

export default {
  name: 'AdminRewardsCompleted',
  components: {
    Pagination,
    AdminRewardsCompletedModal
  },
  mounted () {
    this.getRewardsCompleted()
  },
  data () {
    return {
      action: this.$route.params.action,
      promotionHash: this.$route.params.hash,
      rewardsDisputes: false,
      LoadPage: true,
      rewardsData: [],
      totalRewards: 1,
      currentPage: 1,
      rewardsPerPage: 25,
      showNoResults: false,
      loadingRewards: false,
      completedModalData: {},
      dataLength: 0,
      loadModal: false
    }
  },
  methods: {
    showModal (data) {
      this.completedModalData = data
      this.$bvModal.show('rewards-complete-modal')
    },
    paginationTitle () {
      const currentTotalPosition = this.rewardsPerPage * (this.currentPage - 1)
      return this.$t('showing_rewards', { page_low_range: currentTotalPosition + 1, page_total: this.dataLength + currentTotalPosition, total: this.totalRewards })
    },
    updatePagination (page) {
      this.currentPage = page
      this.getRewardsCompleted()
    },
    getRewardsCompleted () {
      this.showNoResults = false
      this.loadingRewards = true
      this.rewardsData = []

      APIService.getRewardAllData(this.rewardsPerPage, this.currentPage, this.promotionHash, 1).then(response => {
        this.rewardsData = response.data.data.results
        this.rewardsPerPage = response.data.data.pagination.per_page
        this.currentPage = response.data.data.pagination.page
        this.total = response.data.data.pagination.total
        this.dataLength = Object.keys(this.rewardsData).length
        if (this.dataLength === 0) {
          this.showNoResults = true
        }
        this.heading = this.paginationTitle()
        this.loadingRewards = false
      }).catch((response) => {
        let error = response.response.data
        if (error.error_code === 'HTTP-403') {
          this.$router.push({ name: 'admin', params: { 'flushMessage': { type: 'danger', message: error.error_message } } })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
