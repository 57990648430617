<template>
  <div>
    <div class="container">
      <div class="breadcrumbs bg-transparent position-absolute mb-4 mt-1">
        <div class="text-left small px-3 mt-1 mb-sm-4 mb-3">
          <a class="clickable" @click="$router.push('/admin')">{{ $t('admin_dashboard').toUpperCase() }}</a>
          <i class="mr-3 ml-2 fa-solid fa-caret-right"></i>
          <a class="clickable" @click="$router.push('/admin/rewards')">{{ $t('rewards').toUpperCase() }}</a>
          <i class="mr-3 ml-2 fa-solid fa-caret-right" aria-hidden="true"/>
          {{ $t('rewards_' + this.action).toUpperCase() }} / #{{ promotionHash }}
        </div>
      </div>
      <div class="main-container">
        <div class=" p-0 mt-4">
          <h2 v-if="!showNoResults">{{ heading }}</h2>
          <div class="container" v-if="showNoResults">
            <b-alert v-model="showNoResults" variant="light">
              {{ $t('no_rewards_found_' + action) }}
            </b-alert>
          </div>
          <div v-if="this.dataLength > 0">
            <div class="container" v-for="(value, index) in rewardsData" :key="index">
              <div class="col-xl-12 content-box">
                <div class="row row-featured row-featured-category">
                  <div class="col-xl-12  box-title border-bottom ">
                    <div class="inner"><h3 class="mt-2 text-left">{{ value.gig.username }} - {{ value.gig.title }}</h3></div>
                    <div class="row">
                      <div class="col-12 col-md-8 pb-3">
                        <Carousel :images="getVideosFromAssets(value.gig.asset)" :indicators="true"/>
                        <router-link :to="{ name: 'task_info', params: { 'username': value.gig.username, 'slug': value.gig.slug }}" target="_blank">
                          <b-button class="mt-3" variant="primary">View Task</b-button>
                        </router-link>
                      </div>
                      <div class="cols-3 col-md-4 pb-3 border-left pt-2 text-left" :id="'rewardSubmit_' + index">
                        <h4 class="text-left mb-2">{{ $t('questions') }}</h4>
                        <div class="mb-2" v-for="(value, name) in value.questions" :key="name">
                          <p class="text-left un mb-1"><u>{{ $t(name) }}</u></p>
                          <p>{{ value }}</p>
                        </div>
                        <h4 class="mt-2 text-left"><label :for="'taskPrice_' + index" class=" ">{{ $t('rewards') }}</label></h4>
                        <div :id="'promotionWarning_' + index" class="p-1 alert alert-danger text-left mb-3 p-2" style="display:none;">
                          <i class="fa-solid fa-triangle-exclamation"></i>
                          {{ $t('incorrect_price') }}
                        </div>
                        <div class="row" v-if="action === 'outstanding'">
                          <div class="col-7">
                            <input :id="'taskPrice_' + index" min="0" max="50" step="1" type="number" class="form-control" required>
                          </div>
                          <div class="col-5">
                            <b-button class="" v-on:click="handleSubmit(index)" variant="primary">Pay</b-button>
                          </div>
                        </div>
                      </div>
                      <div class="cols-3 col-md-4 pb-3 border-left pt-2 text-left" style="display:none;" :id="'rewardFinished_' + index">
                        <h4 class="mt-2 text-left">{{ $t('rewards') }}</h4>
                        <p>{{ $t('reward_submitted') }}</p>
                      </div>
                      <div class="cols-3 col-md-4 pb-3 border-left pt-2 border-left" style="display:none;" :id="'rewardSubmitting_' + index">
                        <div class="ml-5 pl-5 mt-5">
                          <b-spinner class="ml-4" large label="Large Spinner"/>
                          <div>{{ $t('submitting') }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <b-button v-if="this.dataLength > rewardsPerPage" class="w-50" v-on:click="getGigWaitingForRewards()" variant="primary">Next</b-button>
        </div>
        <div v-if="loading">
          <b-spinner class="mt-5 mb-2 mb-md-4" large label="Large Spinner"/>
          <div class="mb-5">{{ $t('loading') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Carousel from '../../Global/CardCarousel'
import APIService from '../../../apiService'
import helper from '../../../helper'

export default {
  name: 'AdminReward',
  components: {
    Carousel
  },
  data () {
    return {
      loading: true,
      rewardsPerPage: 10,
      currentPage: 1,
      total: 1,
      action: this.$route.params.action,
      promotionHash: this.$route.params.hash,
      LoadingDisputes: false,
      LoadPage: true,
      rewardsData: [],
      searchInput: '',
      sortAdmin: '',
      showNoResults: false,
      heading: '',
      dataLength: 0
    }
  },
  mounted () {
    this.getGigWaitingForRewards()
  },
  methods: {
    getVideosFromAssets (media) {
      let videos = []
      media.forEach(function (asset) {
        let video = {
          'src': helper.formatCloudinaryUrl(asset, 'movie_large'),
          'type': 2,
          'mp4': helper.formatCloudinaryUrl(asset, 'movie_mp4_large')
        }
        videos.push(video)
      })

      return videos
    },
    handleSubmit (hash) {
      document.getElementById('rewardSubmit_' + hash).style.display = 'none'
      document.getElementById('rewardSubmitting_' + hash).style.display = 'block'

      const value = document.getElementById('taskPrice_' + hash).value

      if (value > 50) {
        document.getElementById('rewardSubmitting_' + hash).style.display = 'none'
        document.getElementById('rewardSubmit_' + hash).style.display = 'block'
        document.getElementById('promotionWarning_' + hash).innerText = this.$t('incorrect_price')
        document.getElementById('promotionWarning_' + hash).style.display = 'block'
      } else {
        let data = {
          'amount': value
        }
        APIService.submitReward(this.promotionHash, hash, data).then(response => {
          document.getElementById('rewardSubmitting_' + hash).style.display = 'none'
          document.getElementById('rewardFinished_' + hash).style.display = 'block'
        }).catch(error => {
          document.getElementById('rewardSubmitting_' + hash).style.display = 'none'
          document.getElementById('rewardSubmit_' + hash).style.display = 'block'
          document.getElementById('promotionWarning_' + hash).innerText = error.response.data.error_message
          document.getElementById('promotionWarning_' + hash).style.display = 'block'
        })
      }
    },
    getGigWaitingForRewards () {
      this.loading = true
      this.rewardsData = []
      this.showNoResults = false
      APIService.getRewardAllData(this.rewardsPerPage, 1, this.promotionHash).then(response => {
        this.rewardsData = response.data.data.results
        this.rewardsPerPage = response.data.data.pagination.per_page
        this.currentPage = response.data.data.pagination.page
        this.total = response.data.data.pagination.total
        this.dataLength = Object.keys(this.rewardsData).length
        if (this.dataLength === 0) {
          this.showNoResults = true
        }
        this.heading = this.paginationTitle()
        this.loading = false
      }).catch((response) => {
        let error = response.response.data
        if (error.error_code === 'HTTP-403') {
          this.$router.push({ name: 'admin', params: { 'flushMessage': { type: 'danger', message: error.error_message } } })
        }
      })
    },
    paginationTitle () {
      const currentTotalPosition = this.rewardsPerPage * (this.currentPage - 1)
      return this.$t('showing_rewards', { page_low_range: currentTotalPosition + 1, page_total: this.dataLength + currentTotalPosition, total: this.total })
    }
  }

}
</script>

<style scoped>

</style>
