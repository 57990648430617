<template>
  <div class="container">
    <Breadcrumb class="bg-transparent" :breadcrumbList="[
      { 'name': 'Admin Dashboard', 'link': $router.resolve({ name: 'admin' }).href },
      { 'name': 'Rewards' }
    ]"/>
    <div class="main-container">
      <h1><strong>{{$t('rewards')}}</strong></h1>
      <div class="container" v-if="showNoResults">
        <b-alert v-model="showNoResults" variant="light">
          {{$t('no_rewards_found')}}
        </b-alert>
      </div>
      <div class="container" v-if="!showNoResults && !loadingRewards">
        <div class="col-xl-12 content-box">
          <div class="row row-featured row-featured-category">
            <div class="col-xl-12  box-title border-bottom ">
              <div class="inner"><h2>{{paginationTitle()}}</h2>
              </div>
            </div>
            <div class="container mb-3 mt-3 table-responsive">
              <table class="table table-striped table-bordered add-manage-table table-hover"
                     data-filter="#filter" data-filter-text-only="true">
                <thead>
                <tr>
                  <th>{{$t('name')}}</th>
                  <th>{{$t('active')}}</th>
                  <th>{{$t('actions')}}</th>
                </tr>
                </thead>
                <tbody class="mb-md-0 mb-3 text-left">
                <tr v-for="(reward, index) in rewards" :key="index" class="cursor" >
                  <td data-title="name">{{reward.name}}</td>
                  <td data-title="active" class="text-center">{{reward.active === true ? $t('active') : $t('closed')}}</td>
                  <td data-title="action" class="text-center" style="width: 33%">
                      <b-button class=" mr-2 ml-2" @click="$router.push('/admin/rewards/completed' + '/' + reward.hash)" variant="primary">Completed</b-button>
                      <b-button class=" mr-2 ml-2" @click="$router.push('/admin/rewards/outstanding' + '/' + reward.hash)" variant="primary">Outstanding</b-button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <Pagination :perPage="rewardsPerPage" :currentPage="currentPage" :total="rewardsPerPage" v-on:paginationUpdate="updatePagination" v-if="totalRewards > rewardsPerPage"
                    class="mt-3"/>
      </div>
      <div v-if="loadingRewards">
        <b-spinner class="mt-5 mb-2 mb-md-4" large label="Large Spinner" />
        <div class="mb-5">{{$t('loading')}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from '../../../apiService.js'
import Pagination from '../../../components/Global/Pagination'
import Breadcrumb from '../../../components/Breadcrumbs/Breadcrumb'

export default {
  name: 'AdminRewards',
  components: {
    Breadcrumb,
    Pagination
  },
  data () {
    return {
      rewardsDisputes: false,
      LoadPage: true,
      rewards: [],
      totalRewards: 1,
      currentPage: 1,
      rewardsPerPage: 25,
      showNoResults: false,
      loadingRewards: false
    }
  },
  mounted () {
    this.getRewards()
  },
  methods: {
    paginationTitle () {
      const currentTotalPosition = this.rewardsPerPage * (this.currentPage - 1)
      return this.$t('showing_rewards', { page_low_range: currentTotalPosition + 1, page_total: this.rewards.length + currentTotalPosition, total: this.totalRewards })
    },
    updatePagination (page) {
      this.currentPage = page
      this.getRewards()
    },
    getRewards () {
      this.showNoResults = false
      this.loadingRewards = true
      this.rewards = []
      ApiService.getRewards(this.rewardsPerPage, this.currentPage).then(response => {
        this.rewards = response.data.data.results
        this.rewardsPerPage = response.data.data.pagination.per_page
        this.currentPage = response.data.data.pagination.page
        this.totalRewards = response.data.data.pagination.total
        if (this.rewards.length === 0) {
          this.showNoResults = true
        }
        this.loadingRewards = false
      }).catch((response) => {
        let error = response.response.data
        if (error.error_code === 'HTTP-403') {
          this.$router.push({ name: 'admin', params: { 'flushMessage': { type: 'danger', message: error.error_message } } })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
